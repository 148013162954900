/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import {
    object, shape, string,
} from 'prop-types';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import DeliveryTableV2 from '../../../DeliveryTable/DeliveryTableV2';
import { trackEvent } from '../../../../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const useStyles = makeStyles((theme) => ({
    fullContainer: (countDownColors) => ({
        background: countDownColors?.count_down_background_color || '#ffdc00',
        borderRadius: '8px',
        margin: '44px 0px 18px',
        color: countDownColors?.count_down_text_color || '#000000',
        '@media only screen and (max-width: 768px) ': {
            borderRadius: '0px',
            margin: '32px -15px 12px',
        },
    }),
    innerContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        '@media only screen and (max-width: 768px) ': {
            justifyContent: 'left',
        },
    },
    mainContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '16px 0px',
        '@media only screen and (max-width: 768px) ': {
            padding: '16px 27px ',
        },
    },
    countDownText: {
        display: 'flex',
        color: theme.palette.cms?.green || '#21571D',
        fontFamily: 'inherit',
        width: '100%',
        '@media only screen and (max-width: 768px) ': {
            justifyContent: 'center',
        },
    },
    countDownContainer: {
        marginLeft: '1%',
        '@media only screen and (max-width: 768px) ': {
            marginLeft: '0px',
            width: '50%',
        },
    },
    orderInTheNextText: {
        fontSize: '1.25rem',
        lineHeight: '1.875rem',
        fontFamily: 'Area Variable, LatoBold',
        '@media only screen and (max-width: 768px) ': {
            fontSize: '18px',
            lineHeight: '26px',
        },
    },
    countDownTime: {
        position: 'relative',
        display: 'grid',
        gridTemplateRows: 'auto',
        gridTemplateColumns: '38px 10px',
        gridTemplateAreas: '"time colon" "label ."',
        '&:last-of-type>$colon': {
            color: 'transparent',
        },
        [theme.breakpoints.down(768)]: {
            gridTemplateColumns: '32 7px',
        },
    },
    countDownTimeText: {
        lineHeight: '28px',
        fontSize: '24px',
        gridArea: 'time',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Area Variable, LatoBold',
    },
    colon: {
        gridArea: 'colon',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'LatoBold',
    },
    countDownTimeLabel: {
        gridArea: 'label',
        display: 'flex',
        justifyContent: 'center',
        fontSize: '0.75rem',
        fontWeight: '400',
        lineHeight: '18px',
        color: theme.palette.cms?.black || '#000000',
    },
    buttonContainer: {
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        lineHeight: '30px',
        cursor: 'pointer',
        marginLeft: '3.5%',
        textDecoration: 'underline',
        textDecorationThickness: '1px',
        textUnderlineOffset: '3px',
        color: theme.palette.cms?.black || '#000000',
        '& svg': {
            width: '20px',
            marginLeft: '10px',
            height: '20px',
            '@media only screen and (max-width: 768px) ': {
                marginLeft: '2px',
            },
        },
        '@media only screen and (max-width: 768px) ': {
            marginLeft: '0px',
            fontSize: '14px',
            justifyContent: 'left',
            paddingTop: '9px',
        },
        '@media only screen and (max-width: 360px) ': {
            fontSize: '12px',
        },
    },
    deliveryTableContainer: {
        padding: '0px 0px 24px 0px',
        '@media only screen and (max-width: 768px) ': {
            padding: '0px 8px 7px',
        },
    },
    makeCenter: {
        '@media only screen and (max-width: 768px) ': {
            alignItems: 'center',
        },
    },
    postCutOffContainer: {
        padding: '26px 0px ',
        alignItems: 'center',
        justifyContent: 'center',
        '@media only screen and (max-width: 768px) ': {
            padding: '16px 27px ',
        },
        '& $orderInTheNextContainer': {
            '@media only screen and (max-width: 768px) ': {
                width: '100%',
                textAlign: 'center',
            },
        },
        '& $buttonContainer': {
            '@media only screen and (max-width: 768px) ': {
                justifyContent: 'center',
                width: '100%',
                paddingTop: '4px',
            },
        },
    },
}));

const CountDownTimerBannerV2 = ({
    timeToCutOff, countDownColors, samedayCutOff, sameDayBlock,
}) => {
    const preCutOffHeading = sameDayBlock?.heading || 'Get it there today if you order in the next';
    const postCutOffHeading = sameDayBlock?.post_cut_off_heading || 'We’re no longer accepting deliveries for today, but order now to get it tomorrow.';
    const heading = timeToCutOff ? preCutOffHeading : postCutOffHeading;
    const classes = useStyles(countDownColors);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const dispatch = useDispatch();
    const isMobile = useMediaQuery('(max-width: 768px)');
    useEffect(() => {
        if (typeof window !== 'undefined' && !window.sameDayEventFired) {
            dispatch(trackEvent({
                eventCategory: 'Experiment',
                eventAction: 'Sameday collection',
                eventLabel: 'Variant',
            }));
            window.sameDayEventFired = true;
        }
    }, []);
    const renderContent = (value, label) => (
        <div className={classes.countDownTime}>
            <span className={classes.countDownTimeText}>{value}</span><span className={classes.colon}>:</span>
            <span className={classes.countDownTimeLabel}>{label}</span>
        </div>
    );
    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    const chevronUp = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3813 9.88128C13.723 9.53957 14.277 9.53957 14.6187 9.88128L21.6187 16.8813C21.9604 17.223 21.9604 17.777 21.6187 18.1187C21.277 18.4604 20.723 18.4604 20.3813 18.1187L14 11.7374L7.61872 18.1187C7.27701 18.4604 6.72299 18.4604 6.38128 18.1187C6.03957 17.777 6.03957 17.223 6.38128 16.8813L13.3813 9.88128Z" fill="#2F363D" />
        </svg>
    );
    const chevronDown = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0303 15.5303C12.7374 15.8232 12.2626 15.8232 11.9697 15.5303L5.96967 9.53033C5.67678 9.23744 5.67678 8.76256 5.96967 8.46967C6.26256 8.17678 6.73744 8.17678 7.03033 8.46967L12.5 13.9393L17.9697 8.46967C18.2626 8.17678 18.7374 8.17678 19.0303 8.46967C19.3232 8.76256 19.3232 9.23744 19.0303 9.53033L13.0303 15.5303Z"  />
        </svg>
    );
    const showTimer = () => (
        <Grid item className={classes.countDownContainer}>
            <Typography style={timeToCutOff.hours === 0 ? { color: sameDayBlock?.styling?.rush_color?.color || 'red' } : null} className={classes.countDownText}>
                {renderContent(timeToCutOff?.hours || '00', 'HRS')}
                {renderContent(timeToCutOff?.minutes || '00', 'MINS')}
                {renderContent(timeToCutOff?.seconds || '00', 'SECS')}
            </Typography>
        </Grid>
    );
    return (
        <Grid className={classes.fullContainer} style={{ background: sameDayBlock?.styling?.background_color?.color || '', color: sameDayBlock?.styling?.font_color?.color || '' }}>
            <Grid className={`${classes.mainContainer} ${timeToCutOff ? '' : classes.postCutOffContainer}`}>
                <Grid container direction="row" className={`${classes.innerContainer} ${classes.makeCenter}`}>
                    <Grid item className={classes.orderInTheNextContainer}>
                        <Typography className={classes.orderInTheNextText}>
                            {heading}
                        </Typography>
                    </Grid>
                    {timeToCutOff && !isMobile &&  showTimer()}
                    <Grid role="presentation" className={classes.buttonContainer} onClick={() => toggleCollapse()}>
                        {sameDayBlock?.cta_copy || 'Delivery cutoff times'} {isCollapsed ? chevronUp() : chevronDown()}
                    </Grid>
                </Grid>
                {timeToCutOff && isMobile && showTimer()}
            </Grid>
            {isCollapsed
            && (
                <Grid  className={classes.deliveryTableContainer}>
                    <DeliveryTableV2 samedayCutOff={samedayCutOff} sameDayBlock={sameDayBlock} />
                </Grid>
            )}
        </Grid>
    );
};

CountDownTimerBannerV2.propTypes = {
    countDownColors: shape({
        count_down_background_color: string.isRequired,
        count_down_text_color: string.isRequired,
    }).isRequired,
    timeToCutOff: object.isRequired,
    samedayCutOff: object.isRequired,
    sameDayBlock: object,
};

CountDownTimerBannerV2.defaultProps = {
    sameDayBlock: {},
};

export default CountDownTimerBannerV2;
